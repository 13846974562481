export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (err: unknown, instance: ComponentPublicInstance | null, info: string) => {
    console.warn("ERROR: ", err, info);
  }

  nuxtApp.vueApp.config.warnHandler = (msg: string, instance: ComponentPublicInstance | null, trace: string) => {
    if (msg.startsWith('Slot "default" invoked outside of the render function')) {
      // TODO: Ignore these for now
      return;
    }
    console.warn(msg, trace);
  }
})
